import { Box, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Image, Text } from "@chakra-ui/react";
import PageTransition from "../components/PageTransition";
import SpecList from "../components/SpecList";
import { calculateYearsSinceDate } from "../helpers/dates";

export default function MainPage() {
  return (
    <PageTransition>
      <Box paddingX="6" mt="2rem">
        <HStack spacing="2rem">
          <Box display={{ md: "flex" }}>
            <Image borderRadius="full" boxSize="100px" src="me.webp" />
          </Box>
          <Box>
            <Text>Hey there, I'm Oscar, a passionate software engineer!</Text>
          </Box>
        </HStack>

        <Tabs variant="enclosed" size="md" align="center">
          <TabList>
            <Tab>About me</Tab>
            <Tab>Work</Tab>
            {/* <Tab>Hobbies</Tab> */}
            <Tab>FAQ</Tab>
          </TabList>

          <TabPanels textAlign="left" mt={8}>
            <TabPanel>
              <Text>
                I'm a {calculateYearsSinceDate("1994-07-27")} year old developer based in Gothenburg, dedicated to
                crafting robust applications and exploring new technologies.
              </Text>
              <Text mt="4">
                I've been coding professionally for {calculateYearsSinceDate("2020-07-01")} years, and I particularly
                enjoy working on backend services, cloud infrastructure, and hardware integration.
              </Text>
            </TabPanel>
            <TabPanel>
              <Text>
                I'm primarily a fullstack developer, occasionally handling sys-ops and dev-ops tasks to ensure smooth
                deployment pipelines and efficient cloud environments.
              </Text>

              <Text mt="4">
                My favorite projects involve AI inference services, product databases, and ML dataset management, and I
                enjoy managing everything from CI/CD pipelines to on-prem compute resources.
              </Text>

              <Text mt="4">
                What I enjoy most about my work is the opportunity to be involved in every phase of a project from its
                inception and ongoing support to its final deployment in the cloud.
              </Text>
            </TabPanel>
            {/* <TabPanel>
              <Text>TODO: Hobbies</Text>
            </TabPanel> */}
            <TabPanel>
              <SpecList
                title=""
                caption="Frequently asked questions"
                headingNames={{ key: "Question", value: "Answer" }}
                specs={[
                  { key: "Polyrepo or monorepo?", value: "Polyrepo" },
                  {
                    key: "Containerization 👍 or 👎?",
                    value: "👍 but not a silverbullet solution",
                  },
                  { key: "Kubernetes 👍 or 👎?", value: "Kubernetes goated" },
                  { key: "Dream car?", value: "BMW 3.0 CSL (E21)" },
                  { key: "Favourite svenne-bea?", value: "Lohmanders" },
                ]}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </PageTransition>
  );
}
