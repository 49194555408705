import { Container } from "@chakra-ui/react";
import React from "react";

interface Props {
  children: React.ReactNode;
}

const FullWidthContainer = ({ children }: Props) => {
  return (
    <Container className="App" w="full" maxW="900px">
      {children}
    </Container>
  );
};

export default FullWidthContainer;
