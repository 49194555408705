import { StackDivider, VStack } from "@chakra-ui/react";
import PageTransition from "../components/PageTransition";
import Post from "../components/Post";

const verticalStackMargin = 16;

export default function ProjectsPage() {
  return (
    <PageTransition>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        align="stretch"
        spacing={verticalStackMargin}
        mt={verticalStackMargin}
        mb={verticalStackMargin}
      >
        <Post
          heading="Stay Tuned"
          paragraphs={[
            `More projects and updates will be available here eventually.`,
            `Please check back later for updates! 🙂`,
          ]}
        />
      </VStack>
    </PageTransition>
  );
}
