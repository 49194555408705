import { Button, chakra, Flex, HStack, Link } from "@chakra-ui/react";
import { isValidMotionProp, motion, Transition } from "framer-motion";
import FullWidthContainer from "./FullWidthContainer";

const ColourBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === "children",
});

const Header = () => {
  const transition: Transition = {
    loop: Infinity,
    ease: "linear",
    duration: 5,
  };

  return (
    <ColourBox
      w="full"
      position="sticky"
      borderBottomWidth={1}
      left={0}
      top={0}
      right={0}
      zIndex={100}
      transition={transition}
      borderRadius={8}
      animate={{
        background: [
          "linear-gradient(90deg, #869EB9, #7485ad, #656998)",
          "linear-gradient(90deg, #656998, #869EB9, #7485ad)",
          "linear-gradient(90deg, #7485ad, #656998, #869EB9)",
          "linear-gradient(90deg, #869EB9, #7485ad, #656998)",
        ],
      }}
    >
      <FullWidthContainer>
        <HStack justify="space-between">
          <Flex
            fontSize="3xl"
            height="5rem"
            align="center"
            textColor="white"
            fontWeight="bold"
            justify="center"
            flex="1"
          >
            Oscar's homepage
          </Flex>

          <Flex justify="flex-end">
            <HStack spacing={4}>
              <Link href="/">
                <Button>Home</Button>
              </Link>

              <Link href="/projects">
                <Button>Projects</Button>
              </Link>
            </HStack>
          </Flex>
        </HStack>
      </FullWidthContainer>
    </ColourBox>
  );
};

export default Header;
