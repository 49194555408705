import "./App.css";
import { Box, ChakraProvider } from "@chakra-ui/react";
import MainPage from "./pages/MainPage";
import { theme } from "./styles/theme";
import Header from "./components/Header";
import Footer from "./components/Footer";
import FullWidthContainer from "./components/FullWidthContainer";
import ProjectsPage from "./pages/ProjectsPage";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

function App() {
  return (
    <Router>
      {
        <ChakraProvider theme={theme}>
          <FullWidthContainer>
            <Header />
            <Switch>
              <Route path="/home">
                <MainPage />
              </Route>
              <Route path="/projects">
                <ProjectsPage />
              </Route>
              <Route path="*" render={() => <Redirect to="/home" />}></Route>
            </Switch>
            <Box zIndex={10} pos="relative"></Box>
            <Footer />
          </FullWidthContainer>
        </ChakraProvider>
      }
    </Router>
  );
}

export default App;
