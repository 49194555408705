import { Box, Text } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box w="full" borderTopWidth={1} py={6}>
      <Text fontSize="medium" textAlign={"center"}>
        Oscar Andersson © {new Date().getFullYear()}
      </Text>
    </Box>
  );
};

export default Footer;
